<template>
  <div :class="['c-alert', className]">
    <v-alert
      v-if="error"
      :value="alert"
      :border="!defaultError ? 'left' : null"
      :color="error.color || 'error'"
      :coloredBorder="!defaultError"
      :dark="defaultError"
      :elevation="defaultError ? 2 : 0"
      :icon="defaultError ? 'mdi-alert-octagon-outline' : null"
      dismissible
      @input="dismiss"
    >
      <span v-if="!error.key">{{ $tn(error.text || error) }}</span>
      <span v-if="error.key">{{ $tn(error.key, error.values) }}</span>
    </v-alert>
  </div>
</template>

<script setup lang="ts">
  import { watch, ref } from 'vue';
  import type { ErrorAlert } from '@/interfaces/common';

  export interface Props {
    className?: string;
    defaultError?: boolean;
    alert?: boolean;
    error?: ErrorAlert | null;
    persist?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    defaultError: true,
  });

  const emits = defineEmits<{
    (e: 'dismiss'): void;
  }>();

  const timeout = 8000;

  const dismissErrorTimeOut = ref<number>(0);

  watch(
    () => props.alert,
    () => {
      if (!props.persist && props.error) {
        dismissErrorAutomatically();
      } else if (dismissErrorTimeOut.value) {
        clearTimeout(dismissErrorTimeOut.value);
      }
    },
    {
      immediate: true,
    },
  );

  function dismiss() {
    emits('dismiss');
  }

  function dismissErrorAutomatically() {
    window.setTimeout(() => emits('dismiss'), timeout);
  }
</script>

<style lang="scss">
  .c-alert {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: auto;

    & .v-alert {
      z-index: 9999999;
    }

    &--full-width {
      width: 100%;
      & .v-alert {
        width: 100%;
        max-width: 100% !important;
      }
    }

    &--app {
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      max-width: 40rem;
    }
  }
</style>
