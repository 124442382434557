<template>
  <div class="c-errors">
    <Error
      v-for="(error, key) in errors"
      :key="key"
      class="c-alert--app"
      data-testId="error"
      :alert.sync="error.show"
      :error="error.error"
      @dismiss="error.dismiss"
    />
  </div>
</template>

<script setup lang="ts">
  import { reactive, computed, watch } from 'vue';
  import Error from '@/components/Shared/Error.vue';
  import { userInfoErrorMessage } from '@/store/modules/auth/auth-state';
  import { companiesErrorMessage } from '@/store/modules/companies/companies-state';
  import { assignmentsErrorMessage } from '@/store/modules/assignments/assignments-state';
  import { globalError } from '@/store/modules/global/global-state';
  import { ErrorAlert } from '@/interfaces/common';

  const state = reactive({
    globalAlert: false,
    assignmentsAlert: false,
    companiesAlert: false,
    authAlert: false,
  });

  function dismissAlert(alert: keyof typeof state, callback: () => Promise<null> | void): void {
    state[alert] = false;
    callback();
  }

  function showError(alert: keyof typeof state, error: any): void {
    state[alert] = error !== null;
  }

  function dismissGlobalAlert() {
    dismissAlert('globalAlert', () => {
      globalError.value = null;
    });
  }

  function dismissAssignmentsAlert() {
    dismissAlert('assignmentsAlert', () => {
      assignmentsErrorMessage.value = null;
    });
  }

  function dismissUserInfoAlert() {
    dismissAlert('authAlert', () => {
      userInfoErrorMessage.value = null;
    });
  }

  function dismissCompaniesError() {
    dismissAlert('companiesAlert', () => {
      companiesErrorMessage.value = null;
    });
  }

  type Error = {
    show: boolean;
    error: ErrorAlert | null;
    dismiss: () => void;
  };

  const errors = computed<Record<string, Error>>(() => {
    return {
      coreError: {
        show: state.globalAlert,
        error: globalError.value,
        dismiss: dismissGlobalAlert,
      },
      assignmentsError: {
        show: state.assignmentsAlert,
        error: assignmentsErrorMessage.value,
        dismiss: dismissAssignmentsAlert,
      },
      authUserInfoError: {
        show: state.authAlert,
        error: userInfoErrorMessage.value,
        dismiss: dismissUserInfoAlert,
      },
      companiesError: {
        show: state.companiesAlert,
        error: companiesErrorMessage.value,
        dismiss: dismissCompaniesError,
      },
    };
  });

  watch(
    globalError,
    error => {
      showError('globalAlert', error);
    },
    {
      immediate: true,
    },
  );
  watch(
    assignmentsErrorMessage,
    error => {
      showError('assignmentsAlert', error);
    },
    {
      immediate: true,
    },
  );
  watch(
    userInfoErrorMessage,
    error => {
      showError('authAlert', error);
    },
    {
      immediate: true,
    },
  );
  watch(
    companiesErrorMessage,
    error => {
      showError('companiesAlert', error);
    },
    {
      immediate: true,
    },
  );
</script>

<style lang="scss" scoped>
  .c-errors {
    position: relative;
    z-index: 999999;
  }
</style>
