import { effectScope, reactive } from 'vue';
import router from '@/router/router';

// Define a custom type that matches the structure we need
interface RouteLocationNormalized {
  fullPath: string;
  path: string;
  matched: any[];
  params: Record<string, string>;
  query: Record<string, string>;
  name?: string | symbol;
  hash: string;
  meta: Record<string, any>;
  [key: string]: any;
}

/**
 * this.$router is not reactive, so can not use $router in computed.
 * Relying on this.$route instead
 */
export function isInitialized(route: RouteLocationNormalized) {
  return route.matched.length > 0 || route.fullPath !== '/';
}

let currentRoute: RouteLocationNormalized;

/** Implementation picked from vue2-helpers */
export function useRoute(): RouteLocationNormalized {
  // Ensure router is initialized
  if (!router?.currentRoute?.value) {
    // Return a minimal route object if router isn't ready
    return {
      fullPath: '/',
      path: '/',
      matched: [],
      params: {},
      query: {},
      name: undefined,
      hash: '',
      meta: {},
    } as RouteLocationNormalized;
  }

  if (!currentRoute) {
    const scope = effectScope(true);
    scope.run(() => {
      currentRoute = reactive(router.currentRoute.value);
      router.afterEach((to: RouteLocationNormalized) => {
        assign(currentRoute, to);
      });
    });
  }
  return currentRoute;
}

function assign(target: Record<string, unknown>, source: Record<string, unknown>) {
  for (const key of Object.keys(source)) {
    target[key] = source[key];
  }
  return target;
}

export function onError(err: Error) {
  if (err.name !== 'NavigationDuplicated') {
    throw err;
  }
}
