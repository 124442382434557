import colors, { RGBAToHexA } from '@/constants/colors';
import Vue from 'vue';
import Vuetify, {
  VApp,
  VAlert,
  VTooltip,
  VBtn,
  VIcon,
  VFadeTransition,
  VCardTitle,
  VListItemContent,
  VListItemTitle,
  VCardActions,
  VList,
  VListItemAction,
  VListItem,
  VCheckbox,
  VTextField,
  VMenu,
  VCard,
  VRow,
  VCol,
  VToolbar,
  VDialog,
  VSpacer,
  VTreeview,
  VContainer,
  VForm,
  VProgressCircular,
  VDataTable,
  VSelect,
  VRadio,
  VRadioGroup,
  VCombobox,
  VFileInput,
  VChip,
  VAutocomplete,
  VListItemSubtitle,
  VDatePicker,
  VCardText,
} from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify, {
  components: {
    VApp,
    VAlert,
    VTooltip,
    VBtn,
    VIcon,
    VFadeTransition,
    VCardTitle,
    VListItemContent,
    VListItemTitle,
    VCardActions,
    VList,
    VListItemAction,
    VListItem,
    VCheckbox,
    VTextField,
    VMenu,
    VCard,
    VRow,
    VCol,
    VToolbar,
    VDialog,
    VSpacer,
    VTreeview,
    VContainer,
    VForm,
    VProgressCircular,
    VDataTable,
    VSelect,
    VRadio,
    VRadioGroup,
    VCombobox,
    VFileInput,
    VChip,
    VAutocomplete,
    VListItemSubtitle,
    VDatePicker,
    VCardText,
  },
});

export default new Vuetify({
  lang: {
    locales: { 'de-DE': de, 'en-GB': en },
    current: 'de-DE',
  },
  theme: {
    themes: {
      light: {
        // Vuetify only accepts hex colors
        primary: RGBAToHexA(colors['blue-600']),
        success: RGBAToHexA(colors['green-600']),
        error: RGBAToHexA(colors['red-500']),
      },
    },
  },
});
