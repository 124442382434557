<template>
  <transition name="toast">
    <div
      v-if="toastProps.visible"
      :class="[
        'dnl:fixed dnl:px-4 dnl:py-2 dnl:rounded-lg dnl:shadow-lg dnl:flex dnl:items-center dnl:z-[1000]',
        `dnl:bg-${toastProps.bgColor}`,
        positionClass,
      ]"
      :data-testId="toastProps.testId"
    >
      <svg
        v-if="toastProps.icon"
        class="dnl:mr-2"
        :class="`dnl:w-${toastProps.iconSize} dnl:h-${toastProps.iconSize} dnl:text-${toastProps.color}`"
      >
        <use :href="`/img/icons/icons.svg#${toastProps.icon}`"></use>
      </svg>
      <span
        v-if="toastProps.text"
        :class="`dnl:text-${toastProps.color} ${toastProps.contentClass}`"
        >{{ $t(toastProps.text) }}</span
      >
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { computed, watch } from 'vue';
  import { toastProps } from '@/services/toastService';

  let timer: ReturnType<typeof setTimeout> | null = null;

  const positionClass = computed(() => {
    switch (toastProps.position) {
      case 'top-left':
        return 'dnl:top-5 dnl:left-5';
      case 'top-right':
        return 'dnl:top-5 dnl:right-5';
      case 'bottom-left':
        return 'dnl:bottom-5 dnl:left-5';
      case 'bottom-right':
        return 'dnl:bottom-5 dnl:right-5';
      default:
        return '';
    }
  });

  const show = () => {
    toastProps.visible = true;

    clearTimeout(timer as ReturnType<typeof setTimeout>);
    timer = setTimeout(() => {
      toastProps.visible = false;
    }, toastProps.duration);
  };

  watch(
    () => toastProps.visible,
    value => {
      if (value) {
        clearTimeout(timer as ReturnType<typeof setTimeout>);
        show();
      }
    },
  );
</script>

<style scoped>
  .toast-enter-active,
  .toast-leave-active {
    transition: opacity 0.5s;
  }
  .toast-enter,
  .toast-leave-to {
    opacity: 0;
  }
</style>
