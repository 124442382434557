<template>
  <nav
    aria-label="Breadcrumb"
    data-testid="breadcrumbs"
  >
    <ul class="dnl:list-none dnl:flex dnl:gap-4 dnl:items-center">
      <template
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
      >
        <sl-tooltip
          :disabled="!breadcrumb.tooltipContent"
          class="no-arrow breadcrumb-tooltip"
          placement="bottom-start"
        >
          <ul
            slot="content"
            class="dnl:list-none"
          >
            <li
              v-for="(tooltipContent, tooltipIndex) in breadcrumb.tooltipContent"
              :key="tooltipIndex"
            >
              <span class="dnl:font-medium dnl:inline-block dnl:me-1">
                {{ t(tooltipContent.title) }}
              </span>
              <span>{{ t(tooltipContent.text) }}</span>
            </li>
          </ul>

          <li>
            <router-link
              v-if="breadcrumb.to"
              data-testid="breadcrumb-item"
              class="dnl:no-underline dnl:text-sm dnl:font-medium dnl:hover:text-blue-500! dnl:transition-colors"
              :class="
                index === breadcrumbs.length - 1 ? 'dnl:text-blue-600!' : 'dnl:text-slate-500! '
              "
              :to="breadcrumb?.to"
              :disabled="breadcrumb?.disabled"
            >
              {{ $t(breadcrumb.text) }}
            </router-link>

            <span
              v-else
              data-testid="breadcrumb-item"
              class="dnl:text-sm dnl:font-medium dnl:text-blue-600 dnl:hover:text-blue-500 dnl:transition-colors"
            >
              {{ t(breadcrumb.text) }}
            </span>
          </li>
        </sl-tooltip>

        <dnl-icon
          v-if="index !== breadcrumbs.length - 1"
          :key="'seperator' + index"
          icon="mdi-chevron-right"
          class="dnl:w-6 dnl:h-6 dnl:text-slate-300"
        >
        </dnl-icon>
      </template>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
  import { Breadcrumbs } from '@/interfaces/BreadCrumbs';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  defineProps<{
    breadcrumbs: Breadcrumbs[];
  }>();
</script>

<style scoped>
  sl-tooltip.breadcrumb-tooltip::part(base) {
    --sl-tooltip-background-color: var(--slate-100) !important;
  }

  sl-tooltip.breadcrumb-tooltip::part(body) {
    padding: 0.5rem;

    box-shadow:
      0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
</style>
