import { Company } from './companies-types';
import useFetchNotesAuditor from '@/api/notesAuditorInstance';
import state from './companies-state';
import { FormError } from '@/store/form-types';

export async function getCompanies() {
  const { data: response } = await useFetchNotesAuditor('/companies').get().json();
  const { data, success } = response.value ?? {};

  if (success) {
    const { companies, companyTableHeaders, companyForm } = data;

    state.companies = companies;
    state.companyTableHeaders = companyTableHeaders;
    state.companyForm = companyForm;
  } else {
    state.companiesErrorMessage = 'generic.errors.server.code';
  }
  return response.value;
}

export async function createCompany(companyFormData: Company) {
  const { data: response } = await useFetchNotesAuditor('/companies').post(companyFormData).json();
  const { data, success, errorData } = response.value ?? {};

  if (success) {
    const { companies } = data;
    state.companies = companies;
  } else if (errorData) {
    for (const key in errorData) {
      errorData[key].forEach((error: FormError) => {
        error.errorCode = `companies.form.error.${error.errorCode}.code`;
      });
    }
    state.companiesErrorData = errorData;
  }

  return response.value;
}

export async function updateCompany(companyFormData: Company) {
  const { data: response } = await useFetchNotesAuditor(`/companies/${companyFormData.companyId}`)
    .put(companyFormData)
    .json();

  const { data, success, errorData } = response.value ?? {};

  if (success) {
    const { companies } = data;
    state.companies = companies;
  } else if (errorData) {
    for (const key in errorData) {
      errorData[key].forEach((error: FormError) => {
        error.errorCode = `companies.form.error.${error.errorCode}.code`;
      });
    }
    state.companiesErrorData = errorData;
  }

  return response.value;
}

export async function deleteCompany(companyId: string) {
  const { data: response } = await useFetchNotesAuditor(`/companies/${companyId}`).delete().json();
  const { data, success } = response.value ?? {};

  if (success) {
    const { companies } = data;
    state.companies = companies;
  }

  return response.value;
}
