<template>
  <v-dialog
    data-testid="confirmDialog"
    :contentClass="`c-confirm-dialog ${className}`"
    isVisible.sync="isVisible"
    :value="isVisible"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card class="dnl-px-8 dnl-py-6 dnl-flex dnl-flex-col dnl-gap-4">
      <LoadingOverlay v-if="isLoading" />

      <v-card-title class="headline !dnl-p-0 dnl-mb-2">{{
        dialogTitle.key ? $t(dialogTitle.key, dialogTitle.params) : $t(dialogTitle)
      }}</v-card-title>
      <v-card-text v-if="$slots.default">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="dnl-flex dnl-items-center dnl-justify-center dnl-gap-3 !dnl-p-0">
        <BtnMain
          v-for="(button, index) in dialogButtons"
          :key="index"
          rounded
          :class="button.className"
          :data-testid="button.testId || button.action"
          :btnText="button.text"
          :btnColor="button.color"
          :isLoading="button.showLoading && isLoading"
          @click="$emit(button.action)"
        ></BtnMain>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import Vue from 'vue';
  import BtnMain from '@/components/Shared/Buttons/BtnMain.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';

  export default Vue.extend({
    name: 'ConfirmDialog',
    components: { BtnMain, LoadingOverlay },
    props: {
      className: {
        type: String,
        default: '',
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
      isLoading: {
        type: Boolean,
      },
      dialogTitle: {
        type: [String, Object],
        required: true,
      },
      dialogButtons: {
        type: Array,
        default: () => [],
      },
    },
  });
</script>

<style lang="scss">
  .c-confirm-dialog {
    width: auto !important;
    min-height: auto !important;
    max-width: 69rem !important;

    .v-card {
      &__title.headline {
        font-size: var(--font-size-lg) !important;
        line-height: var(--leading-8);
        word-break: break-word;
      }

      & .subtitle-1 {
        font-size: var(--font-size-base) !important;
        line-height: var(--leading-6) !important;
      }
    }
  }
</style>
