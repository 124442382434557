import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import { env } from '@/constants/env';

const dsn = 'https://2ea6f2a6d3351e7a7bf5efe28dfb1937@sentry.notesauditor.ai/5';

const configs = [
  {
    sources: ['PRODUCTION', 'ANALYSTS'],
    config: {
      environment: 'PRODUCTION',
      tracesSampleRate: 0.5,
    },
  },
  {
    sources: ['STAGING', 'BDOTEST'],
    config: {
      environment: 'STAGING',
      tracesSampleRate: 1.0,
    },
  },
  {
    sources: ['DEVELOPMENT'],
    config: {
      environment: 'DEVELOPMENT',
      tracesSampleRate: 1.0,
    },
  },
];

export function initSentry(sentryEnv: string) {
  const targetConfig = configs.find(config => config.sources.includes(sentryEnv.toUpperCase()));

  if (targetConfig) {
    Sentry.init({
      Vue,
      dsn,
      integrations: [Sentry.browserTracingIntegration()],
      release: env.VITE_ENV_RELEASE_VERSION?.slice(1, -1), // quotes from VITE_ENV_RELEASE_VERSION are removed
      ...targetConfig.config,
    });
  }
}
