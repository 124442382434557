<template>
  <ActionsMenu
    ref="actionsMenu"
    activatorIconClass="dnl:h-5 dnl:w-5 dnl:text-slate-500"
    :distance="10"
    activatorButtonClass="dnl:border dnl:border-solid dnl:border-slate-200 dnl:p-1.25 dnl:rounded-md"
    :items="menuItems"
    @changeLanguage="changeLanguage"
    @logout="logout"
    @navigateToURL="navigateToURL"
    @changeTabSettings="changeTabSettings"
  />
</template>

<script lang="ts" setup>
  import { computed, ref, watch } from 'vue';
  import ActionsMenu from '@/components/Shared/ActionsMenu/ActionsMenu.vue';
  import {
    getLanguages,
    setLanguage,
    setLanguageInUserSettings,
    useLanguageStore,
  } from '@/services/language.service';
  import { useI18n } from 'vue-i18n';

  import { logout } from '@/store/modules/auth/auth-actions';
  import { getAuthToken } from '@/services/auth';
  import { Locale } from '@/constants/languages';
  import { user } from '@/store/modules/auth/auth-state';
  import { tabNameType } from '@/store/modules/settings/settings-state';
  import { TabNameSetting, TabNameSettings } from '@/store/modules/settings/settings-types';

  const { t } = useI18n();

  const actionsMenu = ref<InstanceType<typeof ActionsMenu>>();

  watch(
    () => useLanguageStore.language,
    async value => {
      if (value) {
        const token = getAuthToken();
        if (token) {
          await setLanguageInUserSettings(value);
        }
      }
    },
  );

  const menuItems = computed(() => {
    const languages = getLanguages();
    const languageMenuItems = languages.map(language => {
      return {
        title: `languages.${language.shortCode.toLocaleLowerCase()}`,
        prefix: language.shortCode,
        selected: useLanguageStore.language === language.tag,
        testId: `language-${language.tag}`,
        action: 'changeLanguage',
        meta: {
          tag: language.tag,
        },
      };
    });

    const baseItems = [
      {
        title: 'user.action.menu.selectLanguage',
        icon: 'mdi-web',
        subMenu: languageMenuItems,
        testId: 'selectLanguage',
      },
      {
        title: 'user.action.menu.privacy',
        icon: 'privacy-policy',
        action: 'navigateToURL',
        meta: {
          url: 'user.link.privacy.url',
          target: '_self',
        },
      },
      {
        title: 'user.action.menu.imprint',
        icon: 'information-symbol',
        iconClass: 'dnl:ms-1 dnl:h-3! dnl:w-3!',
        action: 'navigateToURL',
        meta: {
          url: 'generic.imprint.url',
        },
      },
      {
        title: 'user.action.menu.releaseNotes',
        icon: 'mdi-new-box',
        action: 'navigateToURL',
        meta: {
          url: 'user.link.releaseNotes.url',
        },
      },
    ];

    if (!user.value) {
      return baseItems;
    }

    const settingsItem = {
      title: 'user.action.menu.settings',
      icon: 'mdi-cog',
      subMenu: [
        {
          title: 'user.action.menu.settings.tabs',
          subMenu: [
            {
              title: `user.action.menu.settings.tabs.type`,
              icon: 'mdi-tab',
              selected: tabNameType.value === TabNameSettings.type,
              action: 'changeTabSettings',
              meta: {
                value: TabNameSettings.type,
              },
            },
            {
              title: `user.action.menu.settings.tabs.name`,
              icon: 'mdi-tab',
              selected: tabNameType.value === TabNameSettings.name,
              action: 'changeTabSettings',
              meta: {
                value: TabNameSettings.name,
              },
            },
          ],
        },
      ],
    };

    // Add settings item after the languages settings
    baseItems.splice(1, 0, settingsItem);

    return [
      ...baseItems,
      {
        title: 'user.action.menu.logout',
        icon: 'mdi-logout',
        action: 'logout',
      },
    ];
  });

  function changeTabSettings(item: { meta: { value: TabNameSetting } }) {
    tabNameType.value = item.meta.value;
    actionsMenu.value?.closeMenu();
  }

  function navigateToURL(item: { meta: { url: string; target: string } }) {
    const { meta } = item;
    window.open(t(meta.url), meta.target || '_blank');
  }

  function changeLanguage(item: { meta: { tag: Locale } }) {
    setLanguage(item.meta.tag);
    actionsMenu.value?.closeMenu();
  }
</script>
