export const TabNameSettings = {
  name: 'name',
  type: 'type',
} as const;

export type TabNameSetting = (typeof TabNameSettings)[keyof typeof TabNameSettings];

export interface SettingsType {
  itemsPerPage: {
    companies: number;
    assignments: number;
  };
  tabNameType: TabNameSetting;
}
