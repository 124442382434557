import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import { watchEffect } from 'vue';
import { env } from '@/constants/env';
import { useLanguageStore } from '@/services/language.service';
import Toast from '@/components/Shared/Toast.vue';
import Icon from '@/components/Shared/DnlIcon.vue';

import './utils/polyfills';
import './utils/shoelace';
import { setupI18n } from './utils/i18n';
import { initSentry } from './utils/sentry';

const { VITE_APP_ENV } = env;

// Create Vue app instance
const app = createApp(App);

// Register global components
app.component('Toast', Toast);
app.component('DnlIcon', Icon);

// Setup i18n
const i18n = await setupI18n();
app.use(i18n);

// Use router
app.use(router);

// Initialize Sentry
initSentry(app, env.VITE_ENV);

app.config.errorHandler = (err, vm, info) => {
  console.error('Error:', err);
  console.error('Vue component:', vm);
  console.error('Additional info:', info);
};

// Mount the app
app.mount('#app');

// Watch for Stonly widget language changes
watchEffect(
  () => {
    if (VITE_APP_ENV !== 'development' && window.StonlyWidget) {
      (window as any).StonlyWidget(
        'setWidgetLanguage',
        useLanguageStore.language.split('-')[0] || 'de',
      );
    }
  },
  {
    flush: 'post',
  },
);
