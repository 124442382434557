<template>
  <sl-dialog
    data-testid="confirmDialog"
    :class="className"
    no-header
    style="--width: initial"
    role="dialog"
    :open="isVisible"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @sl-request-close="closeDialog"
  >
    <div class="dnl:px-8 dnl:py-6 dnl:flex dnl:flex-col dnl:gap-4">
      <LoadingOverlay v-if="isLoading" />

      <h2
        v-if="dialogTitle"
        class="dnl:text-xl dnl:font-medium dnl:p-0! dnl:mb-2"
      >
        {{ dialogTitle.key ? $t(dialogTitle.key, dialogTitle.params) : $t(dialogTitle) }}
      </h2>
      <span v-if="$slots.default">
        <slot></slot>
      </span>
      <div class="dnl:flex dnl:items-center dnl:justify-center dnl:gap-3 dnl:p-0!">
        <BtnMain
          v-for="(button, index) in dialogButtons"
          :key="index"
          rounded
          :class="button.className"
          :data-testid="button.testId || button.action"
          :btn-text="button.text"
          :btn-color="button.color"
          :is-loading="button.showLoading && isLoading"
          @click="emit(button.action)"
        ></BtnMain>
      </div>
    </div>
  </sl-dialog>
</template>

<script setup lang="ts">
  import BtnMain from '@/components/Shared/Buttons/BtnMain.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';

  defineProps<{
    className?: string;
    isVisible: boolean;
    isLoading?: boolean;
    dialogTitle: string | { key: string; params: Record<string, string> };
    dialogButtons: Array<{
      action: string;
      text: string;
      color?: string;
      className?: string;
      testId?: string;
      showLoading?: boolean;
    }>;
  }>();

  const emit = defineEmits(['close', 'confirm', 'cancel', 'delete', 'save', 'discard']);

  function closeDialog(e?: any) {
    e?.preventDefault();
    emit('close');
  }
</script>

<style scoped>
  sl-dialog::part(panel) {
    max-width: 69rem;
  }

  sl-dialog::part(body) {
    padding: 0;
    border-radius: 1em;
  }
</style>
