const routes = [
  {
    path: '/company/:companyId/assignments',
    name: 'assignments',
    component: () => import('../views/TheAssignments.vue'),
    meta: {
      requiresAuth: true,
    },
    props: route => {
      return { companyId: route.params.companyId };
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('../views/TheCompanies.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pipeline/annex/questions/:companyId/:assignmentId/audit/sorting/:questionIndex',
    name: 'annex-questions-audit',
    component: () => import('../views/TheChecklistFlow.vue'),
    meta: {
      requiresAuth: true,
    },
    props: route => {
      return { companyId: route.params.companyId, assignmentId: route.params.assignmentId };
    },
  },
  {
    path: '/pipeline/annex/questions/:companyId/:assignmentId/audit-numbers',
    name: 'annex-audit-numbers',
    component: () => import('../views/TheNumbersFlow.vue'),
    meta: {
      requiresAuth: true,
    },
    props: route => {
      return {
        companyId: route.params.companyId,
        assignmentId: route.params.assignmentId,
        isReviewingNumbers: false,
        createdAt: route.query.createdAt,
      };
    },
  },
  {
    path: '/pipeline/annex/questions/:companyId/:assignmentId/review-numbers',
    name: 'annex-review-numbers',
    component: () => import('../views/TheNumbersFlow.vue'),
    meta: {
      requiresAuth: true,
    },
    props: route => {
      return {
        companyId: route.params.companyId,
        assignmentId: route.params.assignmentId,
        isReviewingNumbers: true,
        createdAt: route.query.createdAt,
      };
    },
  },
  {
    path: '/pipeline/annex/questions/:companyId/:assignmentId/review/sorting/:questionIndex',
    name: 'annex-questions-review',
    component: () => import('../views/TheChecklistFlow.vue'),
    meta: {
      requiresAuth: true,
    },
    props: route => {
      return { companyId: route.params.companyId, assignmentId: route.params.assignmentId };
    },
  },
  {
    path: '/privacy-policy',
    name: 'data-protection-policy',
    component: () => import('../views/TheDataProtection.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/login/oidc',
    name: 'loginOpenIDConnect',
    component: () => import('../views/TheLogin.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/TheLogin.vue'),
    meta: {
      guest: true,
      layout: null,
    },
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('../views/Icons.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/components',
    name: 'components',
    component: () => import('../views/Components.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    // will match everything
    path: '*',
    redirect: { name: 'companies' },
  },
];

export default routes;
