import { env } from '@/constants/env';
import { createFetch } from '@vueuse/core';
import { handleApiError, handleErrorSentry } from '@/services/apiErrors';
import { token } from '@/store/modules/auth/auth-state';

const useFetchNotesAuditor = createFetch({
  baseUrl: env.VITE_APP_URL_API,
  options: {
    async beforeFetch({ options }) {
      if (options?.headers) {
        options.headers.Authorization = `Bearer ${token.value}`;
        options.headers.timestamp = (new Date().getTime() / 1000).toString();
      }

      return { options };
    },
    async onFetchError(ctx) {
      const { data, response } = ctx;
      let exportErrorCode = '';

      if (data && data instanceof Blob) {
        try {
          const textData = await data.text();
          const parsedData = JSON.parse(textData);
          if (parsedData && parsedData.errorData && parsedData.errorData.errorCode) {
            exportErrorCode = `error.${parsedData.errorData.errorCode}`;
          }
        } catch (e) {
          console.error('Error parsing blob data:', e);
        }
      }

      const statusCode = response?.status;
      const errorCode = data && data.errorData ? data.errorData.errorCode : null;
      const errorCodeTranslationKey = errorCode ? `error.${errorCode}` : null;
      const textToShow = errorCodeTranslationKey || (data && data.detail) || (data && data.msg);

      // We do not want to show a global error message if the error is an export file error
      if (exportErrorCode !== 'exportNotPossibleNumbersNotChecked') {
        try {
          await handleApiError(statusCode, textToShow);
        } catch (e) {
          console.error('Error in handleApiError:', e);
        }
        try {
          handleErrorSentry({ statusCode, data, url: response?.url });
        } catch (e) {
          console.error('Error in handleErrorSentry:', e);
        }
      }

      // In Vue 3, we need to explicitly return both error and data for them to be updated
      const errorValue = exportErrorCode || textToShow || 'Unknown error';
      console.log('Returning error from onFetchError:', errorValue);

      return {
        error: errorValue,
        data,
        response,
      };
    },
  },
  fetchOptions: {
    mode: 'cors',
  },
});

export default useFetchNotesAuditor;
