<template>
  <button
    class="dnl:flex dnl:items-center dnl:justify-center dnl:text-white dnl:py-1.5 dnl:px-5 dnl:font-bold dnl:rounded-full"
    :class="{
      'dnl:text-zink-400! dnl:bg-neutral-200!': disabled,
    }"
    :disabled="disabled || isLoading"
    :aria-disabled="disabled || isLoading"
    :loading="isLoading"
    v-bind="$attrs"
    @click="emit('click')"
  >
    <dnl-icon
      v-if="iconLeft"
      :icon="iconLeft"
      class="dnl:pr-1 dnl:w-6 dnl:h-6"
      :class="`dnl:text-${iconColor}`"
    >
    </dnl-icon>
    <span v-if="showText">
      {{ $t(btnText) }}
    </span>
    <dnl-icon
      v-if="iconRight"
      class="dnl:pl-1 dnl:w-6 dnl:h-6"
      :class="`dnl:text-${iconColor}`"
      :icon="iconRight"
    >
    </dnl-icon>
  </button>
</template>

<script setup lang="ts">
  const emit = defineEmits(['click']);
  defineProps({
    btnColor: { type: String, default: 'primary' },
    showText: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, required: false, default: false },
    btnText: { type: String, required: true },
    iconRight: { type: String, default: '' },
    iconLeft: { type: String, default: '' },
    iconColor: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
  });
</script>
