<template>
  <sl-menu-item
    class="actions-menu no-checkbox divider"
    :data-testid="item.testId"
    :data-selected="item.selected"
    :disabled="item.disabled"
    @click="handleAction(item)"
  >
    <template v-if="item.icon">
      <dnl-icon
        slot="prefix"
        :icon="item.icon"
        :class="['dnl:text-jeans-indigo dnl:h-4 dnl:w-4', item.iconClass]"
      />
    </template>
    <template v-else-if="item.prefix">
      <span
        slot="prefix"
        class="dnl:font-bold dnl:text-slate-500"
        >{{ item.prefix }}</span
      >
    </template>
    <span class="dnl:text-slate-500 dnl:font-medium dnl:text-sm">
      {{ t(item.title) }}
    </span>

    <dnl-icon
      v-if="item.selected"
      slot="suffix"
      icon="mdi-check"
      class="dnl:h-4 dnl:w-4 dnl:ms-17 dnl:text-jeans-indigo"
    />

    <sl-menu
      v-if="item.subMenu"
      slot="submenu"
      class="dnl:min-w-48"
      placement="left"
    >
      <menu-item
        v-for="(subItem, index) in item.subMenu"
        :key="index"
        :item="subItem"
        @menu-action="emit('menu-action', $event)"
      />
    </sl-menu>
  </sl-menu-item>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  import type { MenuItem } from './ActionsMenuTypes';

  const { t } = useI18n();

  defineProps<{
    item: MenuItem;
  }>();

  const emit = defineEmits<{
    (e: 'menu-action', item: MenuItem): void;
  }>();

  function handleAction(item: MenuItem) {
    if (item.action) {
      emit('menu-action', item);
    }
  }
</script>

<style scoped>
  sl-menu-item.no-submenu-icon::part(submenu-icon) {
    display: none;
  }

  sl-menu-item.actions-menu::part(submenu-icon) {
    color: var(--jeans-indigo) !important;
  }

  sl-menu-item.no-checkbox::part(checked-icon) {
    display: none;
  }

  sl-menu-item.actions-menu::part(prefix) {
    margin-inline-end: var(--spacing-8);
  }

  sl-menu-item.divider:not(:last-child)::part(base) {
    border-bottom: solid var(--slate-200) var(--spacing-1) !important;
  }

  sl-menu-item.actions-menu::part(base) {
    padding: var(--spacing-6) 1rem !important;
    background: var(--slate-100) !important;
  }

  sl-menu-item.actions-menu::part(base):hover,
  sl-menu-item.actions-menu:hover,
  sl-menu-item.actions-menu:hover::part(base) {
    background: var(--slate-50) !important;
  }
</style>
