import { reactive, toRefs, computed } from 'vue';
import type { Assignment } from './assignments-types';
import type { AssignmentsState } from './assignments-types';
import type { Maybe } from '@/interfaces/genericTypes';

const assignmentsState = reactive<AssignmentsState>({
  assignments: [],
  assignmentMultiStepForms: [],
  assignmentsErrorMessage: null,
  assignmentsErrorData: null,
  assignmentsWarningData: null,
  exportPDFWarningMessage: null,
  activeAssignmentId: null,
  assignmentsCompanyData: null,
  assignmentUsersForm: [],
  initialTemplateForm: {},
  assignmentAssignedUsers: [],
  activeDocuments: [],
  socketAdded: false,
});

export const {
  activeAssignmentId,
  assignmentsErrorMessage,
  activeDocuments,
  assignments,
  assignmentsCompanyData,
  assignmentsErrorData,
  assignmentsWarningData,
  assignmentUsersForm,
  assignmentAssignedUsers,
  initialTemplateForm,
  assignmentMultiStepForms,
  exportPDFWarningMessage,
} = toRefs(assignmentsState);

export const activeAssignment = computed<Maybe<Assignment>>(() => {
  return assignments.value?.find(
    assignment => assignment.assignmentId === activeAssignmentId.value,
  );
});

export default assignmentsState;
