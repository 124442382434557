<script setup lang="ts">
  import { useVersionChecker } from '@/composables/useVersionChecker';

  const { hasNewVersion, dismissUpdate, reloadApp } = useVersionChecker();
</script>

<template>
  <div
    v-if="hasNewVersion"
    class="dnl:fixed dnl:bottom-4 dnl:right-4 dnl:bg-white dnl:rounded-lg dnl:shadow-lg dnl:z-50 dnl:w-sm dnl:overflow-hidden"
  >
    <hr class="dnl:border-t dnl:border-7 dnl:border-blue-500" />
    <div class="dnl:pt-2 dnl:px-4 dnl:pb-3">
      <div class="dnl:flex dnl:items-start dnl:flex-col dnl:gap-2">
        <div class="dnl:flex dnl:items-center dnl:gap-2">
          <dnl-icon
            icon="update"
            class="dnl:text-slate-600 dnl:h-5 dnl:w-5"
          />
          <h3 class="dnl:text-slate-600 dnl:text-sm dnl:font-medium">{{ $t('update.title') }}</h3>
        </div>
        <p class="dnl:text-slate-400 dnl:text-xs">
          {{ $t('update.message1') }}<br />
          {{ $t('update.message2') }}
        </p>
        <div class="dnl:w-full dnl:flex dnl:justify-end dnl:gap-1">
          <button
            class="dnl:text-blue-500 dnl:text-sm dnl:font-medium dnl:px-6 dnl:py-2 dnl:rounded-lg dnl:hover:bg-blue-50"
            @click="dismissUpdate"
          >
            {{ $t('update.dismiss') }}
          </button>
          <button
            class="dnl:bg-blue-500 dnl:border dnl:border-1 dnl:border-blue-600 dnl:text-white dnl:text-sm dnl:font-medium dnl:px-6 dnl:py-2 dnl:rounded-lg dnl:hover:bg-blue-400"
            @click="reloadApp"
          >
            {{ $t('update.reload') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>