import Dexie from 'dexie';
import { Ref, WatchOptions, watch } from 'vue';

const db = new Dexie('notes_auditor');

db.version(1).stores({
  user: '++',
  checklist: '++',
});

export function getSavedDataFromLocalStorage<T extends string>(storageKeys: T[]): Record<T, any> {
  const data: Record<T, any> = {} as Record<T, any>;

  storageKeys.forEach(key => {
    const item = localStorage.getItem(key);
    data[key] = null;
    if (item) {
      try {
        data[key] = JSON.parse(item);
      } catch {
        data[key] = item;
      }
    }
  });

  return data;
}

export function useStateLocalstorageSync(key: string, value: Ref<any>, options?: WatchOptions) {
  return watch(
    () => value.value,
    () => {
      if (value.value !== undefined && value.value !== null && value.value !== '') {
        localStorage.setItem(key, JSON.stringify(value.value));
      } else {
        localStorage.removeItem(key);
      }
    },
    options,
  );
}

export default db;
