import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import { env } from '@/constants/env';

const dsn = 'https://2ea6f2a6d3351e7a7bf5efe28dfb1937@sentry.notesauditor.ai/5';

const configs = [
  {
    sources: ['PRODUCTION', 'ANALYSTS'],
    config: {
      environment: 'PRODUCTION',
      tracesSampleRate: 0.5,
    },
  },
  {
    sources: ['STAGING', 'BDOTEST'],
    config: {
      environment: 'STAGING',
      tracesSampleRate: 1.0,
    },
  },
  {
    sources: ['DEVELOPMENT'],
    config: {
      environment: 'DEVELOPMENT',
      tracesSampleRate: 1.0,
    },
  },
];

export function initSentry(app: ReturnType<typeof createApp>, sentryEnv?: string) {
  // Default to 'DEVELOPMENT' if no environment is provided
  const environment = sentryEnv?.trim() || 'DEVELOPMENT';

  const targetConfig = configs.find(config => config.sources.includes(environment.toUpperCase()));

  if (targetConfig) {
    Sentry.init({
      app,
      dsn,
      release: env.VITE_ENV_RELEASE_VERSION ? env.VITE_ENV_RELEASE_VERSION.slice(1, -1) : undefined,
      integrations: !env.DEV ? [Sentry.browserTracingIntegration()] : [],
      ...targetConfig.config,
    });
  } else {
    // If no matching config is found, initialize with development config
    Sentry.init({
      app,
      dsn,
      environment: 'DEVELOPMENT',
      tracesSampleRate: 1.0,
      release: env.VITE_ENV_RELEASE_VERSION ? env.VITE_ENV_RELEASE_VERSION.slice(1, -1) : undefined,
      integrations: !env.DEV ? [Sentry.browserTracingIntegration()] : [],
    });
  }
}
