import { ref, onMounted, onUnmounted } from 'vue';
import { env } from '@/constants/env';

const STORAGE_KEY = 'app_version';
const CHECK_INTERVAL = 60 * 1000; // 1 minute in milliseconds

interface VersionResponse {
  release: string;
}

export function useVersionChecker() {
  const hasNewVersion = ref(false);
  const currentVersion = ref<string>(env.VITE_ENV_RELEASE_VERSION);
  let intervalId: number | null = null;

  const checkVersion = async () => {
    try {
      const response = await fetch(`/release.json?_=${Date.now()}`, {
        cache: 'no-store',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });
      const data: VersionResponse = await response.json();
      const storedVersion = localStorage.getItem(STORAGE_KEY);

      // First time running the app
      if (!storedVersion) {
        localStorage.setItem(STORAGE_KEY, data.release);
        currentVersion.value = data.release;
        return;
      }

      // Check if version changed
      if (data.release !== storedVersion) {
        hasNewVersion.value = true;
        currentVersion.value = data.release;
      }
    } catch (error) {
      console.error('Failed to check version:', error);
    }
  };

  const dismissUpdate = () => {
    hasNewVersion.value = false;
  };

  const reloadApp = () => {
    dismissUpdate();
    localStorage.setItem(STORAGE_KEY, currentVersion.value || '');
    // Force a hard reload by changing the location with cache-busting query param
    window.location.href = `${window.location.href.split('?')[0]}?reload=${Date.now()}`;
  };

  onMounted(async () => {
    localStorage.setItem(STORAGE_KEY, currentVersion.value);
    intervalId = window.setInterval(checkVersion, CHECK_INTERVAL);
  });

  onUnmounted(() => {
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
  });

  return {
    hasNewVersion,
    currentVersion,
    dismissUpdate,
    reloadApp,
    // Export checkVersion for testing purposes
    checkVersion,
  };
}
