import { reactive, toRefs, watchEffect } from 'vue';
import { AuthState } from './auth-types';
import {
  getSavedDataFromLocalStorage,
  useStateLocalstorageSync,
} from '@/utils/localStorage/localStorage';
import * as Sentry from '@sentry/vue';

const savedData = getSavedDataFromLocalStorage(['user', 'token', 'settings']);

const state = reactive<AuthState>({
  user: savedData.user,
  token: savedData.token,
  userInfoErrorMessage: null,
  authErrorData: null,
  refreshTimeoutId: undefined,
});

export const { user, userInfoErrorMessage, authErrorData, token } = toRefs(state);

useStateLocalstorageSync('token', token);
useStateLocalstorageSync('user', user);

watchEffect(() => {
  if (user.value) {
    const { value } = user;
    Sentry.setUser({
      id: value.id,
      email: value.email,
    });
  } else {
    Sentry.setUser(null);
  }
});

// synchronizing token over multiple tabs
window.addEventListener('storage', () => {
  const lsToken = localStorage.getItem('token');

  if (!lsToken) return;

  const lsTokenParsed = JSON.parse(lsToken);

  if (lsTokenParsed && token.value && token.value !== lsTokenParsed) {
    try {
      token.value = JSON.parse(lsTokenParsed);
    } catch {
      token.value = lsTokenParsed;
    }
  }
});

export default state;
