import { useLanguageStore } from '@/services/language.service';
import { createI18n } from 'vue-i18n';
import { de_DE, en_GB } from '@/constants/dateTimeFormats';
import { Locale } from '@/constants/languages';
import type { I18n } from 'vue-i18n';

async function loadLocaleMessages() {
  const contexts = import.meta.glob('../translations/*.json');
  const messages: Record<string, any> = {};

  for (const path in contexts) {
    const regex = /([a-z]{2}-[A-Z]{2})/g;
    const match = path.match(regex);
    if (match && match[0]) {
      const locale = match[0];
      const module = await contexts[path]();
      messages[locale] = module.default;
    }
  }

  return messages;
}

export async function setupI18n(locale?: Locale): Promise<I18n> {
  const messages = await loadLocaleMessages();

  const i18n = createI18n({
    legacy: false,
    fallbackWarn: false,
    missingWarn: false,
    globalInjection: true, // Enables global injection of $t, etc
    locale: locale ?? useLanguageStore.language,
    fallbackLocale: 'de-DE',
    messages,
    datetimeFormats: {
      'de-DE': de_DE,
      'en-GB': en_GB,
    },
  });

  return i18n;
}
