<template>
  <div class="dnl:flex-1 dnl:flex dnl:flex-col dnl:min-h-screen dnl:max-w-full dnl:relative">
    <component :is="layout">
      <router-view></router-view>
    </component>
    <GlobalDialog />
    <Errors />
    <Toast />
    <UpdateNotification />
  </div>
</template>

<script setup lang="ts">
  import { computed, watch, watchEffect } from 'vue';
  import { useRoute } from '@/utils/router';
  import Errors from '@/components/Containers/Errors.vue';
  import GlobalDialog from '@/components/Containers/GlobalDialog.vue';
  import DefaultLayout from '@/components/Layouts/DefaultLayout.vue';
  import EmptyLayout from '@/components/Layouts/EmptyLayout.vue';
  import Toast from './components/Shared/Toast.vue';
  import { isInitialized } from '@/utils/router';
  import { token as stateToken } from './store/modules/auth/auth-state';
  import { useI18n } from 'vue-i18n';
  import { useLanguageStore } from '@/services/language.service';
  import { socket, socketConnectWithAuth, socketUpdateToken } from './socket';
  import UpdateNotification from '@/components/UpdateNotification.vue';
  import { initUsersnap } from '@/utils/usersnap';

  // Global styles shared by multiple components
  import '@/styles/app.css';

  const { locale } = useI18n({ useScope: 'global' });

  // Define components
  const components = {
    default: DefaultLayout,
    empty: EmptyLayout,
  } as const;

  const route = useRoute();
  initUsersnap();

  // Updated computed property
  const layout = computed(() => {
    if (!isInitialized(route)) {
      return components.empty;
    }

    const layoutMeta = route.meta?.layout as keyof typeof components | null | undefined;

    if (layoutMeta === null) return components.empty;
    return components[layoutMeta ?? 'default'];
  });

  // Watch for language changes
  watchEffect(() => {
    locale.value = useLanguageStore.language;
  });

  // Watch token changes
  watch(
    () => stateToken.value,
    (newToken: string | null) => {
      if (!newToken) {
        return;
      }

      if (!socket) {
        socketConnectWithAuth(newToken);
      } else {
        socketUpdateToken(newToken);
      }
    },
    { immediate: true },
  );
</script>
