import { computed, reactive, toRefs } from 'vue';
import { SettingsType, TabNameSettings } from './settings-types';
import { defaultItemsPerPage } from '@/constants/common';
import {
  getSavedDataFromLocalStorage,
  useStateLocalstorageSync,
} from '@/utils/localStorage/localStorage';

const localStorageKey = 'dnl-settings-local';

const savedData = getSavedDataFromLocalStorage([localStorageKey])[localStorageKey];

/**
 * Persistent local state, which includes key / value pairs like
 * default pageSize, theme etc
 */
const state = reactive<SettingsType>({
  itemsPerPage: {
    companies: savedData?.itemsPerPage.companies || defaultItemsPerPage,
    assignments: savedData?.itemsPerPage.assignments || defaultItemsPerPage,
  },
  tabNameType: savedData?.tabNameType || TabNameSettings.type,
});

const { itemsPerPage, tabNameType } = toRefs(state);

const synced = computed(() => ({
  itemsPerPage: itemsPerPage.value,
  tabNameType: tabNameType.value,
}));

useStateLocalstorageSync(localStorageKey, synced, { deep: true });

export default state;

export { tabNameType };
