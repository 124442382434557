<template>
  <div @keydown.esc="closeMenu">
    <sl-popup
      placement="bottom-end"
      :distance="distance"
      :anchor="anchorId"
      :active="menuActive"
      flip
      @mouseleave="onMouseLeave"
    >
      <sl-menu
        ref="menuElContainer"
        class="dnl:bg-slate-200 dnl:min-w-48"
      >
        <MenuItemComponent
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          @menu-action="handleMenuAction"
        />
      </sl-menu>
    </sl-popup>

    <button
      :id="anchorId"
      :data-testId="activatorTestId"
      :class="activatorButtonClass"
      @click="onTriggerClick"
    >
      <dnl-icon
        :class="activatorIconClass"
        :icon="activatorIcon"
      >
      </dnl-icon>
    </button>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { onClickOutside } from '@vueuse/core';
  import type { MenuItem } from './ActionsMenuTypes';
  import MenuItemComponent from './MenuItem.vue';
  import uuid from '@/utils/uuid';

  const menuActive = ref(false);
  const menuElContainer = ref(null);
  const anchorId = uuid();

  const props = withDefaults(
    defineProps<{
      items: MenuItem[];
      activatorIcon?: string;
      activatorIconClass?: string;
      activatorTestId?: string;
      closeOnMouseLeave?: boolean;
      activatorButtonClass?: string;
      distance?: number;
    }>(),
    {
      activatorIcon: 'app-burger-menu',
      activatorTestId: 'app-menu-button',
      activatorIconClass: 'dnl:h-3.5 dnl:w-5.5',
      activatorButtonClass:
        'dnl:flex dnl:items-center dnl:bg-slate-100 dnl:py-[9px] dnl:px-[6px] dnl:rounded-md',
      closeOnMouseLeave: true,
      distance: 20,
    },
  );

  const emit = defineEmits<{
    (event: string, item: MenuItem): void;
  }>();

  onClickOutside(
    menuElContainer,
    () => {
      if (!menuActive.value) return;
      menuActive.value = false;
    },
    {
      ignore: ['#app-menu-button'],
      detectIframe: true,
    },
  );

  function handleMenuAction(item: MenuItem) {
    if (item.action) {
      emit(item.action, item);
    }
  }

  function onTriggerClick() {
    menuActive.value = !menuActive.value;
  }

  function closeMenu() {
    menuActive.value = false;
  }

  function onMouseLeave() {
    if (props.closeOnMouseLeave) {
      closeMenu();
    }
  }

  defineExpose({
    closeMenu,
  });
</script>
