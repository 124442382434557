export function parseJwt(token: string): {
  session_lifetime: number;
  session_refresh_when_idling: boolean;
  user_id: string;
  exp: number;
} | null {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  if (!base64Url) return null;
  try {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
      })
      .join('');

    const jsonPayload = decodeURIComponent(payload);
    return JSON.parse(jsonPayload);
  } catch {
    return null;
  }
}
