<template>
  <div
    class="dnl:flex dnl:px-4 dnl:py-3 dnl:items-center dnl:justify-between dnl:sticky dnl:top-0 dnl:z-50 dnl:bg-white dnl:shadow-[0px_0px_4px_0px_rgba(0,0,0,0.05)] dnl:border-b dnl:border-b-slate-100 dnl:border-b-solid"
  >
    <router-link
      title="Home"
      :to="user ? '/' : '/login'"
      data-testId="home"
      class="dnl:flex dnl:items-center"
    >
      <img
        v-if="user && user.userGroup"
        :src="logoUrl"
        :alt="user.userGroup"
        class="dnl:h-6"
      />
      <img
        v-else
        src="@/assets/icon-br-m.png"
        alt="DNL logo"
        class="dnl:h-8"
      />
    </router-link>

    <BreadCrumbs
      v-if="breadcrumbs.length > 0"
      :breadcrumbs="breadcrumbs"
    />

    <AppBarMenu />
  </div>
</template>

<script lang="ts" setup>
  import { computed, onBeforeUnmount, watch } from 'vue';
  import { useRoute } from '@/utils/router';
  import BreadCrumbs from '@/components/Shared/BreadCrumbs.vue';
  import defaultLogo from '@/assets/icon-br-m.png';
  import { breadcrumbsStore, setBreadcrumbs } from '@/services/breadcrumbs.service';
  import { user } from '@/store/modules/auth/auth-state';
  import AppBarMenu from '@/components/Shared/AppBar/AppBarMenu.vue';

  const route = useRoute();

  const breadcrumbs = computed(() => breadcrumbsStore.breadcrumbs);
  const logoUrl = computed(() => user.value?.userGroupLogoURL || defaultLogo);

  watch(
    () => route.path,
    () => {
      setBreadcrumbs([]);
    },
  );

  /** fix for the bug when breadcrumbs are visible after relogin in the wrong place
   * TODO: reset the whole app state on logout
   */
  onBeforeUnmount(() => {
    setBreadcrumbs([]);
  });
</script>
