import { createRouter, createWebHistory } from 'vue-router';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import routes from './routes';
import { token } from '@/store/modules/auth/auth-state';
import { refreshUserCredentials } from '@/store/modules/auth/auth-actions';
import { globalError } from '@/store/modules/global/global-state';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (to.name === 'loginOpenIDConnect') {
      await handleLoginOpenIDConnectRoute(next);
    } else if (to.name === 'login' && token.value) {
      const isLogout = to.query.logout;
      if (!isLogout) return next({ name: 'companies' });
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      // If token is not present, redirect to login
      if (!token.value) {
        return next({
          path: '/login',
          query: { redirect: to.fullPath },
        });
      }

      // Refresh user credentials on the first load
      if (!from.name) {
        try {
          const { success } = await refreshUserCredentials({ initLogoutTimer: true });
          if (!success) {
            return next({
              path: '/login',
              query: { redirect: to.fullPath },
            });
          }
        } catch {
          return next({
            path: '/login',
            query: { redirect: to.fullPath },
          });
        }
      }
    }

    next();
  },
);

async function handleLoginOpenIDConnectRoute(next: NavigationGuardNext) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const redirectURL = params.redirect;
  const isVerified = params.is_verified === 'true';
  const isSSOLoginError = params.error === 'sso_failed';
  const { token: ssoToken } = params;

  if (isSSOLoginError || !isVerified) {
    globalError.value = {
      text: !isVerified ? 'login.error.unverified' : 'login.error.sso',
    };
    return next({ path: '/login' });
  } else if (ssoToken) {
    token.value = ssoToken;
    await refreshUserCredentials({
      forceRefresh: true,
      initLogoutTimer: true,
    });

    return next({ path: redirectURL || '/companies' });
  }
}

router.onError((error: Error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    console.log('chunk error, reloading');
  }
});

export default router;
