/**
 * Generates a Universally Unique Identifier (UUID)
 * @returns {string} The generated UUID.
 */

function uuid(): string {
  return crypto.randomUUID();
}

export default uuid;
